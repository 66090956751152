<template>
  <div>
    <v-app-bar app :color="colorPalete.bg" :height="`${navHeight}px`" :min-width="360" flat>
      <v-container class="flex justify-between items-center h-full gap-4 px-0">
        <v-btn :color="colorPalete.color" depressed right x-large icon @click.stop="open">
          <v-icon x-large>$menu</v-icon>
        </v-btn>
        <div class="flex flex-none items-center gap-2">
          <Notifications :color-palete="colorPalete" :use-theme="useTheme" :nav-height="navHeight" />
          <v-btn
            v-if="hasCompany"
            :color="colorPalete.bg"
            height="45"
            depressed
            class="btn--menu pa-2"
            @click.stop="open"
          >
            <div class="flex items-center justify-center gap-3" :class="colorPalete.text">
              <span style="max-width: 170px" class="leading-none text-body-1 truncate mt-1" v-text="company.name" />
              <v-avatar :color="colorPalete.color" size="36">
                <div
                  class="flex items-center justify-center w-full h-full"
                  :style="composeCompanyInitialsCss(company.name)"
                >
                  <v-img v-if="brand.logo" :src="brand.logo" />
                  <span v-else class="text-body-1 font-weight-medium" v-text="composeCompanyInitials(company.name)" />
                </div>
              </v-avatar>
            </div>
          </v-btn>
        </div>
      </v-container>
    </v-app-bar>
    <!-- Navigation drawer when open -->
    <v-navigation-drawer
      v-model="drawer"
      :color="colorPalete.bg"
      mini-variant-width="100%"
      mini-variant
      stateless
      fixed
      app
      class="z-50"
    >
      <v-card :color="colorPalete.bg" elevation="0">
        <div class="flex items-center justify-between w-full pa-8 pr-4">
          <RouterLink :to="goToHome()" class="block" @click.native="close">
            <NumberoIcon class="flex items-center" :class="colorPalete.text" />
          </RouterLink>
          <v-btn :color="colorPalete.color" plain depressed right x-large icon @click.native="close">
            <v-icon x-large>$clear</v-icon>
          </v-btn>
        </div>
        <div class="flex flex-col gap-y-8">
          <!-- Main application menu -->
          <v-list class="list--menu text-body-2 space-y-2 ma-2 pa-0">
            <v-list-group
              v-for="n in userMobileNav"
              :key="n.id"
              v-model="expand[n.id]"
              :color="menuColor(n.active)"
              class="pa-0"
            >
              <template #activator>
                <v-list-item
                  :key="n.id"
                  :to="n.childrens ? undefined : n.route"
                  :class="[
                    colorPalete.text,
                    n.active
                      ? useTheme
                        ? 'list--active--dark'
                        : 'list--active--light'
                      : expand[n.id]
                      ? 'list--hover'
                      : '',
                  ]"
                  @click="n.childrens ? null : close()"
                >
                  <v-list-item-content>
                    <v-list-item-title class="flex items-center gap-2">
                      <span class="leading-none text-body-1" v-text="n.title" />
                      <v-icon
                        v-if="n.childrens"
                        :color="colorPalete.color"
                        x-small
                        :class="expand[n.id] ? 'rotate-180' : 'rotate-0'"
                      >
                        $chevronDown
                      </v-icon>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <!-- Application submenu -->
              <v-card v-if="n.childrens" :color="useTheme ? '#000' : '#FFF'" elevation="0" class="mx-4 mt-1 pa-2">
                <v-list-item
                  v-for="subn in n.childrens"
                  :key="subn.id"
                  :to="subn.route"
                  :color="menuColor(n.active)"
                  :class="colorPalete.text"
                  @click="close"
                >
                  <v-list-item-title>
                    <v-list-item-content>
                      <v-list-item-title class="flex items-center gap-2">
                        <span class="leading-none text-body-2" v-text="subn.title" />
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item-title>
                </v-list-item>
              </v-card>
            </v-list-group>
          </v-list>
          <!-- Menu shortcuts -->
          <v-list class="list--menu rounded-lg text-body-2 space-y-2 my-2 mx-6 pa-0">
            <!-- Switch Company -->
            <SwitchCompanyModal v-if="hasCompany" :key="isLoadingLocale" use-delay @switch="close">
              <template #button="{ open }">
                <v-list-item class="button-hover" :class="colorPalete.text" @click="open()">
                  <v-list-item-content>
                    <v-list-item-title class="flex items-center gap-2">
                      <v-icon :color="colorPalete.color" small> $repeat </v-icon>
                      <span
                        class="leading-none text-body-1"
                        v-text="trans('workspace.title.change_company', 'Mainīt kompāniju')"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </SwitchCompanyModal>
            <!-- Add new company -->
            <AddCompanyModal v-if="drawer" use-delay @create="close">
              <template #button="{ open }">
                <v-list-item class="button-hover" :class="colorPalete.text" @click="open()">
                  <v-list-item-content>
                    <v-list-item-title class="flex items-center gap-2">
                      <v-icon :color="colorPalete.color" small> $plus </v-icon>
                      <span
                        class="leading-none text-body-1"
                        v-text="trans('button.add.company', 'Pievienot kompāniju')"
                      />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </AddCompanyModal>
            <!-- Support -->
            <SupportModal use-delay @request="close">
              <template #button="{ open }">
                <v-list-item class="button-hover" :class="colorPalete.text" @click="open()">
                  <v-list-item-content>
                    <v-list-item-title class="flex items-center gap-2">
                      <v-icon :color="colorPalete.color" small> $headphones </v-icon>
                      <span class="leading-none text-body-1" v-text="trans('workspace.support.title', 'Atbalsts')" />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </SupportModal>
          </v-list>
          <v-list class="list--menu rounded-lg text-body-2 space-y-2 my-2 mx-6 pa-0">
            <div>
              <!-- Logout -->
              <v-list-item class="button-hover" :class="colorPalete.text" @click="logout">
                <v-list-item-content>
                  <v-list-item-title class="flex items-center gap-2">
                    <v-icon :color="colorPalete.color" small> $logout </v-icon>
                    <span class="leading-none text-body-1" v-text="trans('menu.logout', 'Iziet')" />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </div>
      </v-card>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
  import { Component, Mixins, Prop } from 'vue-property-decorator';
  import useUserNav from '@/services/UserNav';
  import useCopyObject from '@/services/CopyObject';
  import DEFAULT_COMPANY_BRAND from '@/constants/mocks/company/CompanyBrand';
  import DEFAULT_COMPANY_DETAILS from '@/constants/mocks/company/CompanyDetails';
  import DocumentRoute from '@/enums/config/document/Route';
  import DocumentType from '@/enums/config/document/Type';
  import DashboardRouteName from '@/enums/config/router/Dashboard';
  import ProfileRouteName from '@/enums/config/router/Profile';
  import type { RawLocation } from 'vue-router';
  import type IUserNav from '@/interfaces/config/IUserNav';
  import type IColorSchema from '@/interfaces/config/IColorSchema';
  import type ICompanyBrand from '@/interfaces/company/ICompanyBrand';
  import type ICompanyDetails from '@/interfaces/company/ICompanyDetails';
  import AuthMixin from '@/mixins/Auth';
  import CompanyModule from '@/store/modules/Company';
  import LoadingModule from '@/store/modules/Loading';
  import NumberoIcon from '@/components/icons/brands/Numbero.vue';
  import Notifications from '@/components/global/nav/app/Notifications.vue';
  import SwitchCompanyModal from '@/components/global/modals/SwitchCompany.vue';
  import AddCompanyModal from '@/components/global/modals/AddCompany.vue';
  import SupportModal from '@/components/global/modals/Support.vue';

  @Component({
    components: {
      NumberoIcon,
      Notifications,
      SwitchCompanyModal,
      AddCompanyModal,
      SupportModal,
    },
  })
  export default class AppMobileNav extends Mixins(AuthMixin) {
    @Prop() colorPalete!: IColorSchema;
    @Prop() navHeight!: number;
    @Prop() useTheme!: boolean;

    public drawer: boolean = false;
    public expand: Record<string, boolean> = {};

    /*****         computed       *****/

    public get hasCompany(): boolean {
      return CompanyModule.hasCompany;
    }

    public get isLoadingLocale(): boolean {
      return LoadingModule.isLoadingLocale;
    }

    public get userMobileNav(): IUserNav[] {
      return useUserNav().userMobileNav();
    }

    public get company(): ICompanyDetails {
      return useCopyObject().copy(CompanyModule.getCompany, DEFAULT_COMPANY_DETAILS);
    }

    public get brand(): ICompanyBrand {
      return useCopyObject().copy(CompanyModule.getCompany, DEFAULT_COMPANY_BRAND);
    }

    public get DocumentType(): typeof DocumentType {
      return DocumentType;
    }

    public get DashboardRouteName(): typeof DashboardRouteName {
      return DashboardRouteName;
    }

    public get DocumentRoute(): typeof DocumentRoute {
      return DocumentRoute;
    }

    /*****         watchers       *****/
    /*****         methods        *****/

    public open(): void {
      this.drawer = true;
    }

    public close(): void {
      this.drawer = false;
    }

    public async logout(): Promise<void> {
      await this.authLogout();
      this.close();
    }

    /*****         helpers        *****/

    public menuColor(isActive: boolean): string {
      return isActive ? (this.useTheme ? '#000' : '#FFF') : this.colorPalete.bg;
    }

    public goToHome(): RawLocation {
      return this.hasCompany
        ? { name: DashboardRouteName.LIST, params: { document: DocumentRoute.ALL } }
        : { name: ProfileRouteName.DATA };
    }

    private resetExpand(): void {
      this.expand = this.userMobileNav.reduce((acc, n) => {
        acc[n.id] = false;
        return acc;
      }, {} as Record<string, boolean>);
    }

    /*****      vue lifecycle     *****/

    beforeMount() {
      this.resetExpand();
    }
  }
</script>
