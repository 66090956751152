import { Component, Vue } from 'vue-property-decorator';
import SortField from '@/enums/generics/SortField';
import type IRecurringListResponse from '@/interfaces/document/response/IRecurringListResponse';
import DocumentRepository from '@/repository/Document';
import CompanyModule from '@/store/modules/Company';
import PageModule from '@/store/modules/Page';

@Component
export default class ListRecurringsMixin extends Vue {
  public async ListRecurringsMixin(
    companyUUID: string = CompanyModule.getCompanyUUID,
  ): Promise<IRecurringListResponse> {
    await PageModule.SET_MISSING_URL_PARAMS({ templates: 1, plain: 0 });
    // Check if sort_field is valid
    const sortField = PageModule.getUrlParams.sort_field;
    const acceptableFields = [SortField.RECIPIENT, SortField.STATUS, SortField.DATE, SortField.TOTAL_WITH_VAT];
    if (sortField && !acceptableFields.includes(sortField)) {
      await PageModule.SET_URL_PARAMS({ sort_field: SortField.DATE });
    }

    return (await DocumentRepository.list(companyUUID, PageModule.getUrlParams)).data;
  }
}
